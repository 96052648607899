<template>
  <div class="core">
      <img class="coreImg" src="@/assets/core/coreImg.png" alt="">
      <!-- <div class="coreNav">
          <div class="coreNavItem">
              <p>用户优惠返利</p>
              <div class="navbarItemLine">

              </div>
          </div>
          <div class="coreNavItem">
               <p>虚拟网店助力实体及流量变现</p>
              <div class="navbarItemLine">

              </div>
          </div>
      </div> -->
      <div class="invite">
          <div class="module_top">
              <p class="module_title">剁手记邀请您如何参与优惠返利</p>
              <img class="icon" src="@/assets/aboutUs/bot_icon.png" alt="">
          </div>
          <div class="stepOne">
              <img class="stepImg" src="@/assets/core/stepOne.png" alt="">
              <div class="stepTitle">
                 自购省钱必备
                  <div class="stepBg">
                      
                  </div>
              </div>
              <div class="stepImg">

              </div>
          </div>
          <div class="stepTop">
              <div class="stepTopItem">
                  <div class="stepTopItemTop">
                      <img class="stepTopImg" src="@/assets/core/search_icon.png" alt="">
                      <p class="stepTopTitle">01/找商品</p>
                  </div>
                  <p>复制商品链接，打开剁手记app自动跳转搜索，或在搜索框搜商品名</p>
              </div>
              <img class="stepLine" src="@/assets/core/arrows.png" alt="">
              <div class="stepTopItem">
                  <div class="stepTopItemTop">
                      <img class="stepTopImg" src="@/assets/core/click_icon.png" alt="">
                      <p class="stepTopTitle">02/选中商品</p>
                  </div>
                  <p>商品列表可查看优惠券，返现奖励点击商品购买</p>
              </div>
              <img class="stepLine" src="@/assets/core/arrows.png" alt="">
              <div class="stepTopItem">
                  <div class="stepTopItemTop">
                      <img class="stepTopImg" src="@/assets/core/coupon_icon.png" alt="">
                      <p class="stepTopTitle">03/领优惠券</p>
                  </div>
                  <p>优惠券领取界面，点击“立即领取”跳转淘宝商品页面</p>
              </div>
              <img class="stepLine" src="@/assets/core/arrows.png" alt="">
              <div class="stepTopItem">
                  <div class="stepTopItemTop">
                      <img class="stepTopImg" src="@/assets/core/order_icon.png" alt="">
                      <p class="stepTopTitle">04/下单立减</p>
                  </div>
                  <p>下单后自动使用优惠券，成功确认收货后可在app内查看佣金并提现</p>
              </div>
          </div>
          <div class="stopOne_img">
              <img src="@/assets/core/stepOne_img1.png" alt="">
              <img src="@/assets/core/stepOne_img2.png" alt="">
              <img src="@/assets/core/stepOne_img3.png" alt="">
              <img src="@/assets/core/stepOne_img4.png" alt="">
          </div>
          <div class="stepOne">
              <img class="stepImg" src="@/assets/core/stepTwo.png" alt="">
              <div class="stepTitle">
                 分享赚钱必备
                  <div class="stepBg">
                      
                  </div>
              </div>
              <div class="stepImg">

              </div>
          </div>
          <div class="stepTopTwo">
              <div class="stepTopItem">
                  <div class="stepTopItemTop">
                      <img class="stepTopImg" src="@/assets/core/search_icon.png" alt="">
                      <p class="stepTopTitle">01/找商品</p>
                  </div>
                  <p>复制商品链接，打开剁手记app自动跳转搜索，或在搜索框搜商品名</p>
              </div>
              <img class="stepLineTwo" src="@/assets/core/stepTwo_line.png" alt="">
              <div class="stepTopItem">
                  <div class="stepTopItemTop">
                      <img class="stepTopImg" src="@/assets/core/share_icon.png" alt="">
                      <p class="stepTopTitle">02/点击分享</p>
                  </div>
                  <p>商品列表可查看优惠券，返现奖励点击“分享”按钮</p>
              </div>
              <img class="stepLineTwo" src="@/assets/core/stepTwo_line.png" alt="">
              <div class="stepTopItem">
                  <div class="stepTopItemTop">
                      <img class="stepTopImg" src="@/assets/core/cash_icon.png" alt="">
                      <p class="stepTopTitle">03/分享得佣金</p>
                  </div>
                  <p>跳转商品分享，可选择分享商品图，推荐语，下单确认收货后可获得返现奖励</p>
              </div>
          </div>
          <div class="stepTwo_img">
              <img  src="@/assets/core/stepTwo_img1.png" alt="">
              <img src="@/assets/core/stepTwo_img2.png" alt="">
              <img src="@/assets/core/stepTwo_img3.png" alt="">
              <div class="bgIcon">
              </div>
              <div class="bgIcon1">
              </div>
              <div class="bgIcon2">
              </div>
          </div>
      </div>
      <div class="introduce">
          <div class="module_top">
              <p class="module_title">剁手记助力线下实体将流量变现</p>
              <img class="icon" src="@/assets/aboutUs/bot_icon.png" alt="">
          </div>
          <p class="introduce_context">扩大您的团队成员，无需时刻分享推荐商品或督促成员下单购买商品，只要您的团队成员使用剁手记下单，您便能拥有返利奖励</p>
          <p class="methods">通过以下三种任意方式邀请好友成为您的团队成员</p>
          <div class="invite_step">
              <div class="invite_stepItem">
                  <p class="invite_stepItem-title">1）进入“我的”选择邀请好友，通过链接分享，邀请好友加入</p>
                  <img class="inviteImg" src="@/assets/core/appImg1.png" alt="">
              </div>
              <div class="invite_stepItem">
                   <p class="invite_stepItem-title">2）通过邀请海报，已分享海报方式邀请好友。</p>
                   <img class="inviteImg" src="@/assets/core/appImg2.png" alt="">
              </div>
              <div class="invite_stepItem">
                  <p class="invite_stepItem-title">3）通过面对面扫码或直接给予对方您的邀请码</p>
                  <img class="inviteImg" src="@/assets/core/appImg3.png" alt="">
              </div>
          </div>
          <img class="introduceBg" src="@/assets/core/method_Bg.png" alt="">
      </div>
      <div class="friend">
          <div class="friend_left"> 
              <p class="friend_left_top">通过“我的剁友”查看当前好友状态</p>
              <p class="friend_left_bot">好友分为直邀好友与间邀好友，您可查看当前每位进入您团队好友的收益情况</p>
          </div>
          <img class="friend_right" src="@/assets/core/friend_img.png" alt="">
      </div>
      <div class="team">
          <img class="team-img" src="@/assets/core/order_img.png" alt="">
          <div class="teamRight">
              <p class="friend_left_top">通过“我的订单”查看团队订单</p>
              <p class="friend_left_bot">当您的团队成员下单完成后，系统将自动跟进订单状态，以及显示您所获得的返利收益</p>
          </div>

      </div>
      <div class="income">
           <div class="friend_left"> 
              <p class="friend_left_top">如何查看团队收益</p>
              <p class="friend_left_bot">进入“我的”选择网店收益，即可查看当前“可提收益”“已提收益”及“预估收益”</p>
          </div>
          <img class="friend_right" src="@/assets/core/team_img.png" alt="">
      </div>
  </div>
</template>

<script>
export default {

}

</script>
<style  scoped>
.coreImg{
    width:100%;
    height: 3.2rem;
    display: block;
}
.coreNav{
    height:0.6rem;
    background: #FFFFFF;
    display: flex;
    border: 0.01px solid #EFEFEF;
    justify-content: center;
    align-items: center;
}
.coreNav .coreNavItem{
    /* margin: 0 3.1rem; */
   width:8.3rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.coreNav .coreNavItem > p{
    height:0.22rem;
    font-size: 0.16rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333;
    line-height: 0.22rem;
}
.navbarItemLine{
    margin-top:0.03rem;
    width: 0.2rem;
    height: 0.04rem;
    background: #F70D08;
    border-radius: 0.03rem;
}
.module_top{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.module_title{
    height: 0.4rem;
    font-size: 0.28rem;
    
    font-weight: 400;
    color: #333333;
    line-height:0.4rem; 
}
.icon{
    width:0.2rem;
    height:0.06rem;
    display: block ;
}
.invite{
    padding-top:0.8rem;
    box-sizing: border-box;
}
.stepOne{
    display: flex;
    margin: 0 3.2rem;
    justify-content: space-between;
    align-items: center;
}
.stepImg{
    width:1.68rem;
    height:1.44rem;
    display: block;
}
.stepTitle{
    position: relative;
    font-size: 0.2rem;
    
    font-weight: 500;
    color: #333333;
    line-height: 0.3rem;
}

.stepBg{
    position: absolute;
    width: 1.2rem;
    height: 0.14rem;
    background: #F62B15;
    border-radius: 0.12rem;
    opacity: 0.1;
    bottom: 0;
    left:0;
}
.stepTop{
    margin:0.38rem 3.2rem 0.24rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.stepTopImg{
    width:0.4rem;
    height: 0.4rem;
}
.stepTopItemTop{
    display: flex;
    align-items: center;
    margin-bottom: 0.1rem;
}
.stepTopItem{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.stepTopItem > p{
    font-size: 0.12rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #333333;
    line-height: 0.18rem;
    width:2rem;
}
.stepTopTitle{
    font-size: 0.16rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.22rem;
}
.stepLine{
    width:0.86rem;
    height: 0.09rem;
}
.stopOne_img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.12rem;
    
}
.stopOne_img > img{
    width:2.55rem;
    height:4.54rem;
    margin:0 0.44rem;
}
.stepLineTwo{
    width:1.38rem;
    height:0.09rem;
    margin: 0 0.4rem;
}
.stepTopTwo{
     display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:0.24rem;
}
.stepTwo_img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.12rem;
    position:relative
}
.stepTwo_img > img{
     width:2.55rem;
    height:4.54rem;
    margin:0 0.8rem;
}
.bgIcon{
    width: 0.82rem;
    height: 0.82rem;
    background: #FFF5F5 linear-gradient(90deg, #FFAC99 0%, #FE5F7B 100%);
    opacity:0.1;
    position:absolute;
    left:10.4rem;
    bottom: 1.6rem;
    border-radius: 50%;
    z-index: -1;
}
.bgIcon1{
    width: 0.82rem;
    height: 0.82rem;
    background: #FFF5F5 linear-gradient(90deg, #FFAC99 0%, #FE5F7B 100%);
    opacity:0.1;
    position:absolute;
    left:6.2rem;
    bottom: 0.4rem;
    border-radius: 50%;
    z-index: -1;
}
.bgIcon2{
    width: 0.82rem;
    height: 0.82rem;
    background: #FFF5F5 linear-gradient(90deg, #FFAC99 0%, #FE5F7B 100%);
    opacity:0.1;
    position:absolute;
    left:14.8rem;
    bottom: 0.8rem;
    border-radius: 50%;
    z-index: -1;
}
.introduce_context{
    font-size: 0.16rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #666666;
    line-height: 0.3rem;
    margin:0.1rem auto 0;
   
}
.introduce{
    position:relative;
}
.methods{
    font-size: 0.22rem;
    
    font-weight: 500;
    color: #F70D08;
    line-height: 0.36rem;
    margin-top:0.45rem;
    margin-bottom: 0.55rem;
}
.invite_step{
    display: flex;
    justify-content: center;
    align-items: center;
}
.invite_stepItem-title{
    margin-bottom: 0.16rem;
    font-size: 0.12rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #333333;
    line-height: 0.18rem;
    width:2rem;
}
.invite_stepItem{
    margin:0 0.73rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.71rem;
}
.inviteImg{
    width:2.73rem;
    height:4.84rem;
}
.introduceBg{
    width:100%;
    height:2.71rem;
    position: absolute;
    bottom: 0;
    left:0;
    z-index: -1;
}
.friend{
    width:100%;
    height: 6rem;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
}
.friend_left{
    width:6.7rem;
    margin-right:1.28rem;
    text-align: left;
    margin-bottom: 0.2rem;
}
.friend_left_top{
    font-size: 0.20rem;

    font-weight: bold;
    color: #333333;
    line-height: 0.29rem;
}
.friend_left_bot{
    font-size: 0.16rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #333333;
    line-height: 0.36rem;
}
.friend_right{
    width:4.82rem;
    height: 4.79rem;
    margin-top:1.21rem;
}
.team{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6rem;
}
.team-img{
    width:4.82rem;
    height: 4.8rem;
    margin-top:1.2rem;
}
.teamRight{
    text-align: left;
    margin-left: 1.7rem;
}
.income{
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F8F8F8;
    
}
</style>